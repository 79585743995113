import React from "react"

import Img from "gatsby-image"
const LAVSecond = ({ data }) => {
  return (
    <>
      <div className="lifeAtVisiomate">
        <div class="second">
          <div class="div1">
            <Img
              fluid={data.LVMTSecondDiv1.childImageSharp.fluid}
              alt="LVMSecond"
              className="fullDivImage"
            />
          </div>
          <div class="div2">
            <p>
              Visiomate promote activities which are vital for software
              development workfore to relex and energizing for Fresh Software
              Challenges, Renewing Our Strength for Innovation and Growth.
            </p>
          </div>
          <div class="div3">
            <Img
              fluid={data.LVMTSecondDiv3.childImageSharp.fluid}
              alt="LVMSecond"
              className="fullDivImage"
            />
          </div>
          <div class="div4">
            <Img
              fluid={data.LVMTSecondDiv4.childImageSharp.fluid}
              alt="LVMSecond"
              className="fullDivImage"
            />
          </div>
          <div class="div5">
            <Img
              fluid={data.LVMTSecondDiv5.childImageSharp.fluid}
              alt="LVMSecond"
              className="fullDivImage"
            />
          </div>
          <div class="div6">
            <Img
              fluid={data.LVMTSecondDiv6.childImageSharp.fluid}
              alt="LVMSecond"
              className="fullDivImage"
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default LAVSecond
