import React from "react"
import Img from "gatsby-image"
const LAVThird = ({ data }) => {
    return (
        <div>
            <div className="lifeAtVisiomate">
                <div class="third">
                    <div class="div1">
                        <p>
                            Visiomate believe in playing a significant role in empowering the
                            youth to acquire essential skills and knowledge vital for
                            fostering successful and fulfilling careers.
                        </p>
                    </div>
                    <div class="di2">
                        <Img
                            fluid={data.LVMThirdDiv2.childImageSharp.fluid}
                            alt="LVMThird"
                            className="fullDivImage"
                        />
                    </div>
                    <div class="div3">
                        <Img
                            fluid={data.LVMThirdDiv3.childImageSharp.fluid}
                            alt="LVMThird"
                            className="fullDivImage"
                        />
                    </div>
                    <div class="div4">
                        <Img
                            fluid={data.LVMThirdDiv4.childImageSharp.fluid}
                            alt="LVMThird"
                            className="fullDivImage"
                        />
                    </div>
                    <div class="div5">
                        <Img
                            fluid={data.LVMThirdDiv5.childImageSharp.fluid}
                            alt="LVMThird"
                            className="fullDivImage"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LAVThird
