import React from "react"
import Img from "gatsby-image"
const LAVFirst = ({ data }) => {
  return (
    <div>
      <div className="lifeAtVisiomate">
        <div class="first">
          <div class="div1">
            <p>
              Our Commitment to the Industry: Fostering a Culture of Work-Life
              Balance and Growth
            </p>
          </div>
          <div class="div2">
            <Img
              fluid={data.lifeatvisiomateDiv2.childImageSharp.fluid}
              alt="lifeAtVisiomate"
              className="fullDivImage"
            />
          </div>
          <div class="div3">
            <Img
              fluid={data.lifeatvisiomateDiv3.childImageSharp.fluid}
              alt="lifeAtVisiomate"
              className="fullDivImage"
            />
          </div>
          <div class="div4">
            <Img
              fluid={data.lifeatvisiomateDiv4.childImageSharp.fluid}
              alt="lifeAtVisiomate"
              className="fullDivImage"
            />
          </div>
          <div class="div5">
            <Img
              fluid={data.lifeatvisiomateDiv5.childImageSharp.fluid}
              alt="lifeAtVisiomate"
              className="fullDivImage"
            />
          </div>
          <div class="div6">
            <Img
              fluid={data.lifeatvisiomateDiv6.childImageSharp.fluid}
              alt="lifeAtVisiomate"
              className="fullDivImage"
            />
          </div>
          <div class="div7">
            <Img
              fluid={data.lifeatvisiomateDiv7.childImageSharp.fluid}
              alt="lifeAtVisiomate"
              className="fullDivImage"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LAVFirst
